@import "../../styles/variables";

.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-100);
}

@media (max-width: 768px) {
    .logo {
        width: 150px;
    }
}


