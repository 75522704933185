@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'CodecPro200';
  src: local('CodecPro200'), url(./fonts/Codec-Pro-ExtraLight.ttf) format('opentype');
}

@font-face {
  font-family: 'CodecPro300';
  src: local('CodecPro300'), url(./fonts/CodecPro-Light.ttf) format('opentype');
}

@font-face {
  font-family: 'CodecPro500';
  src: local('CodecPro500'), url(./fonts/CodecPro-Bold.ttf) format('opentype');
}
