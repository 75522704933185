@import "variables";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // -ms-overflow-style: none;
  // scrollbar-width: none;
  // user-select: none;
  font-family: $fCodecProR;
}

// *::-webkit-scrollbar {
//   display: none;
// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
  outline: none;
  color: rgb(240, 240, 240);
  -webkit-text-fill-color: rgb(240, 240, 240);
  background: white;
  font: inherit; /* Inherit font properties from parent element */
}

input {
  border: none;
  outline: none;
  background: none;
  font: inherit; /* Inherit font properties from parent element */
  padding: 0; /* Adjust padding if needed */
}

html, body {
  overscroll-behavior: none;
  background-color: $background;
  width: 100%;
  height: 100%;
  font-size: 10px;
}

button {
  // all: unset;
}

.uppercase {
  text-transform: uppercase;
}

h1 {
  font-family: 'CodecPro500', sans-serif;
  color:  #3F4134;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;
}

.font12 {
  font-family: 'CodecPro200', sans-serif;
  font-size: 12px;
  font-weight: 200;
  line-height: 16.8px;
  text-align: center;
}

.font10 {
  font-family: 'CodecPro300', sans-serif;
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
}

.t-align-l {
  text-align: left;
}

.fw300 {
  font-family: 'CodecPro300', sans-serif;
}

h2 {
  font-size: 16px;
  font-family: 'CodecPro500', sans-serif;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #232323;
}

h3 {
  font-family: 'CodecPro300', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.6px;
  text-align: left;
  color: #232323;
}

h4 {
  color: #232323;
  font-family: 'CodecPro500', sans-serif;
  font-weight: 500;
  letter-spacing: -0.01em;
}

h5 {
  font-family: 'CodecPro200', sans-serif;
  font-weight: 200;
  letter-spacing: -0.01em;
  text-align: left;
}

.defaultText {
  font-size: 12px;
  font-weight: 200;
  font-family: 'CodecPro200', sans-serif;
  line-height: 16.8px;
  text-align: left;
  color: #8C8C8C;
}

.white-text {
  color: #FFFFFF;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.text {
  font-size: 12px;
}

ul, li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.gray100 {
  color: #232323;
}

.codec300 {
  font-family: 'CodecPro300', sans-serif;
  font-weight: 300;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
}

/* menu on desktop */
.desktop-nav .menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  box-sizing: border-box;
  gap: 6px;
}

.desktop-nav .menu-items {
  position: relative;
  box-sizing: border-box;
}

.desktop-nav .dropdown {
  position: absolute;
  right: 0;
  left: auto;
  z-index: 9999;
  list-style: none;
  background-color: #F2F3EC;
  display: none;
  border-top: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 5.34px 8.6px 0px #00000008;
  width: max-content;
}

.desktop-nav .dropdown.show {
  display: inline-block;
  box-sizing: border-box;
}

.desktop-nav .dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.mobile-nav {
  //display: none;
}

.customCottageSlider {
  .slick-slide:not(.slick-active) {
    padding: 0px;
  }
}

.menu-items:hover {
  border-bottom-width: 1px;
  border-bottom-color: rgba(35, 35, 35, 1);
  border-bottom-style: solid;
  margin-bottom: -1px;
}

/* page content */
.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h1 {
  font-size: 2rem;
}

#error-page {
  /* center content on the page */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
}

.loading-btn {
  width: 300px;
}

//@media screen and (min-width: 1280px) {
//  .defaultText {
//    font-size: 16px;
//    font-weight: 300;
//    line-height: 24px;
//    font-family: 'CodecPro300', sans-serif;
//    letter-spacing: -0.01em;
//    text-align: left;
//  }
//}

@media (min-width: 600px) {
  h1 {
    font-size: 26px;
    line-height: 30px;
  }

  .font12 {
    font-size: 14px;
    line-height: 18.8px;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 40px;
    line-height: 48px;
  }

  .font12 {
    font-family: 'CodecPro300', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }

  h2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    font-family: 'CodecPro500', sans-serif;
  }

  h3 {
    font-size: 14px;
    font-weight: 300;
    line-height: 19.6px;
    font-family: 'CodecPro300', sans-serif;
  }

  .customCottageSlider {
    .slick-slide:not(.slick-active) {
      padding: 20px;
    }
  }
}

@media (min-width: 1280px) {
  h1 {
    font-size: 56px;
    line-height: 67.2px;
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    font-family: 'CodecPro500', sans-serif;
    line-height: 24px;
    letter-spacing: -0.01em;
  }

  h3 {
    font-size: 24px;
    font-weight: 200;
    line-height: 33.6px;
    letter-spacing: -0.01em;
    text-align: left;
    font-family: 'CodecPro200', sans-serif;
  }

  .defaultText {
    font-family: 'CodecPro300', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: -0.01em;
  }

  .customCottageSlider {

    .slick-slide:not(.slick-active) {
      padding: 60px 80px;
    }

    .slick-list {
      .slick-track {
        .slick-slide {
          width: 650px;
        }
      }
    }
  }
}

//@media (min-width: 1536px) {
//  h1 {
//    font-size: 60px;
//    line-height: 72.2px;
//  }
//
//  .font12 {
//    font-size: 21px;
//    line-height: 27px;
//  }
//
//  h2 {
//    font-size: 32px;
//    line-height: 40px;
//  }
//
//  h3 {
//    font-size: 28px;
//    line-height: 35.6px;
//  }
//}
//
//@media (min-width: 2048px) {
//  h1 {
//    font-size: 82px;
//    line-height: 90.2px;
//  }
//
//  .font12 {
//    font-size: 32px;
//    line-height: 40px;
//  }
//
//  h2 {
//    font-size: 46px;
//    line-height: 58px;
//  }
//
//  h3 {
//    font-size: 32px;
//    line-height: 37.6px;
//  }
//}
